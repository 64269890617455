
import { HTMLElementEvent } from '@/utils/types'
import AdminUserRow from '@/views/admin/UserRow.vue'
import { onMounted, computed, defineComponent, reactive } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'AdminUserPage',
  components: {
    AdminUserRow
  },
  setup () {
    const store = useStore()

    const newUser = reactive({
      email: '',
      isEnabled: true,
      roles: ['ROLE_USER'],
      username: ''
    })

    const userRequests = computed(() => store.state.users.actionRequest)
    const users = computed(() => store.state.users.users)

    function addUser () {
      store.dispatch('users/addUser', newUser)
    }
    function getAllUsers () {
      store.dispatch('users/getAll')
    }
    function setRoles (event: HTMLElementEvent<HTMLSelectElement>) {
      if (event.target.value === 'admin') {
        newUser.roles.push('ROLE_ADMIN')
      } else {
        const i = newUser.roles.indexOf('ROLE_ADMIN')
        if (i > -1) {
          newUser.roles.splice(i, 1)
        }
      }
    }

    onMounted(() => {
      getAllUsers()
    })

    return { userRequests, users, addUser, newUser, setRoles }
  }
})
