
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'AdminUserRowPage',
  props: {
    user: { type: Object, required: true }
  },
  setup (props) {
    const store = useStore()

    const isUpdating = ref(false)
    const profile = ref('user')
    const tmpUser = reactive({
      id: null,
      email: '',
      roles: ['ROLE_ANONYMOUS'],
      username: ''
    })

    function getRoles (): string[] {
      if (profile.value === 'user') {
        return ['ROLE_USER']
      } else {
        return ['ROLE_USER', 'ROLE_ADMIN']
      }
    }
    function initProfile () {
      if (props.user.roles.length === 1) {
        profile.value = 'user'
      } else {
        profile.value = 'admin'
      }
    }
    function initTmpUser () {
      tmpUser.id = props.user.id
      tmpUser.email = props.user.email
      tmpUser.roles = props.user.roles
      tmpUser.username = props.user.username
      initProfile()
    }
    function closeForm () {
      isUpdating.value = false
    }
    function deleteUser () {
      if (confirm('Do you confirm you want to delete this user ?')) {
        store.dispatch('users/deleteUser', props.user.id)
      }
    }
    async function disableUser () {
      try {
        await store.dispatch('users/disableUser', props.user.id)
      } catch (err) {
        alert(err)
      }
    }
    function enableUser () {
      store.dispatch('users/enableUser', props.user.id)
    }
    function initForm () {
      initTmpUser()
      isUpdating.value = true
    }
    async function updateUser () {
      tmpUser.roles = getRoles()
      try {
        await store.dispatch('users/updateUser', tmpUser)
        closeForm()
      } catch (err) {
        alert(err)
      }
    }

    onMounted(() => {
      initTmpUser()
    })

    return { isUpdating, updateUser, tmpUser, closeForm, enableUser, disableUser, deleteUser, initForm, profile }
  }
})
