
import { menuItems, MenuItem } from './data/menuItems'
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Header',
  setup () {
    const store = useStore()
    const router = useRouter()

    const me = computed(() => store.state.security.me)
    const isAuth = computed(() => store.getters['security/getIsAuth'])
    const allowedMenuItems = computed(() => {
      return menuItems.filter((item: MenuItem) => {
        return me.value.roles.includes(item.role)
      })
    })

    function logout () {
      store.dispatch('security/logout')
      router.push({ path: '/login' })
    }

    return { allowedMenuItems, me, isAuth, logout }
  }
})
