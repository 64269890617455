
import { defineComponent, ref, reactive, computed, watch } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'LoginPage',
  setup () {
    const store = useStore()
    const router = useRouter()

    const forgottenPasswordSend = reactive({
      email: '',
      message: '',
      show: false,
      status: true
    })
    const password = ref('')
    const username = ref('')

    const me = computed(() => store.state.security.me)
    const securityRequests = computed(() => store.state.security.actionRequest)

    async function login () {
      await store.dispatch('security/login', {
        username: username.value,
        password: password.value
      })
    }
    function sendForgottenPasswordEmail () {
      store.dispatch('security/forgottenPasswordSend', forgottenPasswordSend.email)
        .then((res: any) => {
          forgottenPasswordSend.status = true
          forgottenPasswordSend.message = res.data.message
          forgottenPasswordSend.email = ''
        })
        .catch((err: any) => {
          forgottenPasswordSend.status = false
          forgottenPasswordSend.message = err.message || err.response.data.message
        })
    }

    watch(me, () => {
      router.push({ path: '/' })
    })

    return { login, password, username, forgottenPasswordSend, securityRequests, sendForgottenPasswordEmail }
  }
})
