<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home'
})
</script>

<template>
  <div class="home">
    <h1>Bienvenue</h1>
  </div>
</template>
