
import { defineComponent } from 'vue'
import LayoutHeader from '@/views/layout/Header.vue'

export default defineComponent({
  name: 'App',
  components: {
    LayoutHeader
  }
})
